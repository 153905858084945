import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let fade = document.querySelectorAll(".fading-animation");

fade.forEach((card) => {
  let offset = card.dataset.offset;
  if (offset === undefined) {
    offset = 5; // default value
  }

  gsap.from(card, {
    opacity: 0,
    delay: 0.3,

    scrollTrigger: {
      trigger: card,
      markers: false,
      start: `top bottom-=${offset}%`,
      end: "bottom+=10% top+=5%",
      toggleActions: "play reverse play reverse",
    },
  });
});
