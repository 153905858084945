import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const timelineContainer = document.getElementById("timeline-container");
if (timelineContainer) {
  const timelineRows = timelineContainer.querySelectorAll(".timeline__row");
  const lastTimelineRows = timelineContainer.querySelector(
    ".timeline__row:last-child"
  );
  let lastTimelineRowsHeight = lastTimelineRows.offsetHeight;

  timelineContainer.style.setProperty(
    "--timeline-height-last-child",
    `${lastTimelineRowsHeight}px`
  );

  gsap.to(".timeline", {
    opacity: 1,
    scrollTrigger: {
      trigger: timelineContainer,
      start: "top center",
      end: `bottom center`,
      toggleActions: "play none play reverse",
    },
  });

  gsap.to(".timeline", {
    "--timeline-progress": 100,
    ease: "none",
    scrollTrigger: {
      scrub: 0.8,
      markers: false,
      trigger: timelineContainer,
      start: "top center",
      end: `bottom center`,
    },
  });

  timelineRows.forEach((timelineRow) => {
    const timelineCol = timelineRow.querySelectorAll(".timeline__col");

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: timelineRow,
        markers: false,
        start: "top center",
        end: "bottom top",
        toggleActions: "play reverse play reverse",
      },
    });

    tl.to(timelineRow, {
      "--timeline-point-opacity": 1,
    });

    tl.to(
      timelineCol,
      {
        "--timeline-point-opacity": 1,
        opacity: 1,
        y: 0,
      },
      "<"
    );
  });
}
