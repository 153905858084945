const body = document.querySelector("body");
const header = document.querySelector("#header .navbar");
const hero = document.getElementById("hero");
const invertedClass = "navbar-inverted";
const pyAbundance = 20;

// Add padding to hero based to header height
export function headerPadding() {
  hero.style.paddingBlock = `${header.clientHeight + pyAbundance}px`;
}

// Scroll Up scroll Down
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

export function scrollDownUp() {
  const currentScroll = window.scrollY;

  if (currentScroll <= 100) {
    header.classList.remove(scrollUp);
    return;
  }

  if (currentScroll > lastScroll && !header.classList.contains(scrollDown)) {
    // down
    header.classList.remove(scrollUp);
    header.classList.add(scrollDown);
  } else if (
    currentScroll < lastScroll &&
    header.classList.contains(scrollDown)
  ) {
    // up
    header.classList.remove(scrollDown);
    header.classList.add(scrollUp);
  }
  lastScroll = currentScroll;
}

// Add inverted class to header
export function headerInverted() {
  if (window.scrollY >= 200) {
    header.classList.add(invertedClass);
  } else {
    header.classList.remove(invertedClass);
  }
}

// Open Navbar on mobile
const collapseHeader = document.getElementById("navbarSupportedContent");
const hamburgerMenu = document.querySelector(".hamburger-menu");

collapseHeader.addEventListener("show.bs.collapse", (event) => {
  body.classList.add("overflow-y-hidden");
  hamburgerMenu.classList.add("active");
  header.classList.add(invertedClass);
});

collapseHeader.addEventListener("shown.bs.collapse", (event) => {});

collapseHeader.addEventListener("hide.bs.collapse", (event) => {
  hamburgerMenu.classList.remove("active");
});

collapseHeader.addEventListener("hidden.bs.collapse", (event) => {
  body.classList.remove("overflow-y-hidden");

  if (window.scrollY <= 200) {
    header.classList.remove(invertedClass);
  }
});
