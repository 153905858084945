import "bootstrap";
import "./fading-animation";
import { headerInverted, headerPadding, scrollDownUp } from "./header";
import "./timeline";

window.addEventListener("DOMContentLoaded", (event) => {
  headerPadding();
  headerInverted();
});

window.addEventListener("resize", (event) => {
  headerPadding();
});

window.addEventListener("scroll", (event) => {
  scrollDownUp();
  headerInverted();
});
